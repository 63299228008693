import * as actionTypes from "../constants/ActionTypes";
import * as endpoints from "../constants/Endpoints";
import * as siteConstants from "../constants/SiteConstants";
import * as utils from "../helpers/Utils";
import * as DataPusher from "../helpers/DataPusher";
import * as watcherActions from "./watcher";

export function showPartsResultPage(data) {
  return { type: actionTypes.SHOW_PARTS_RESULT_PAGE, data };
}

export function tryFetchingPartOENumbersAsync(dataToPost) {
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken");

    dispatch(fetchingPartDetails());
    dispatch(showPartsResultPage());

    let configObject = {
      channel: siteConstants.PART_OE_CHANNEL + "_" + loginToken,
      stream: siteConstants.PART_OE_STREAM + "_" + utils.createUUID(),
      successCB: function (pusherEvent) {
        try {
          dispatch(watcherActions.clearWatcher(configObject.stream))
          console.log(
            " -- Message from ",
            configObject.channel,
            configObject.stream
          );
          console.log(" -- Pusher Event from FetchingPartOENumbersAsync ", {
            pusherEvent,
          });
          console.log("pusher OE Event", pusherEvent);
          let pusherResponse = pusherEvent.message.payload;
          if (pusherResponse && pusherResponse.operation_list) {
            let fitleredOEList = pusherResponse.operation_list.filter(
              (opItem, opIndex) => {
                return opItem.oe_number;
              }
            );

            let oeNumbers = fitleredOEList.map((opItem, opIndex) => {
              return {
                oe_number: opItem.oe_number,
                brand: dataToPost.brand_name,
                part_description: opItem.description,
              };
            });
            dispatch(tryFetchingPartListFromOEAsync(oeNumbers, dataToPost?.fin));
          }
        } catch (ex) {
          console.log(ex);
          dispatch(onFinValidated({}));
        }

        DataPusher.unsubscribeFrom(configObject.channel);
      },
    };
    dataToPost.pub_sub_details = {
      "channel": configObject.channel,
      "event": configObject.stream,
    };

    dataToPost.fin = dataToPost.fin.toUpperCase();

    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CUSTOMER-TOKEN": loginToken,
    };
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

    function bindCallback(){
      fetch(endpoints.PART_GT_OE_ASYNC_API, {
        method: "post",
        headers: headers,
        body: JSON.stringify(dataToPost),
      })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let isSuccess =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "SUCCESS"
              ? true
              : false,
          isUnauthorised =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "UNAUTHORIZED"
              ? true
              : false,
          isFailure =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "ERROR"
              ? true
              : false;
        if (isSuccess) {
          console.log(jsonResponse);
        } else if (isUnauthorised) {
          console.error("Exception unauthorised");
        } else {
          window.location.href = "/";
        }
      })
      .catch((ex) => {
        console.log(ex);
      });
    }

    DataPusher.subscribeTo(configObject, bindCallback);

    dispatch(
      watcherActions.tryAddingToWatcher(
        configObject.stream,
        siteConstants.mediumTimeout,
        { configObject, loginToken, url: location.href }
      )
    );

    
  };
}

export function tryFetchingPentanaPartsListFromS3API( pusherResponse , searchedText){
	return function (dispatch) {
		let url = pusherResponse.payload.url;
		if( window.partsPanelConfig.corsUrl ){
			url = window.partsPanelConfig.corsUrl + url;
		}
		fetch( url, {
				method: 'get',
				headers: {
					'Accept': 'application/json, text/plain, */*',
					'Content-Type': 'application/json'
				}
		})
		.then(response => response.json())
		.then(jsonResponse => {
      let sortedMergedData = mergeAndSortApiDbData(jsonResponse, searchedText);
			pusherResponse.payload = sortedMergedData;
			dispatch(onPartsListFromOEFetched( pusherResponse ));
		})
		.catch(ex => {
			console.log(ex);
      dispatch(onPartsListFromOEFetched({ status: "COMPLETED" }));
		});

	}
}

export function fetchingPartDetails(data) {
  return { type: actionTypes.FETCHING_PARTS, data };
}

export function onPartListSubscription(data) {
  return { type: actionTypes.PART_LIST_FROM_OE_SUBSCRIPTION, data };
}

export function onPartsListFromOEFetched(data) {
  return { type: actionTypes.PART_LIST_FROM_OE_DATA_FETCH, data };
}

export function mergeAndSortApiDbData(result, searchedText) {
  let apiData = result.api_data;
  let dbData =  result.db_data;
	console.log("apidata, dbdata", apiData, dbData);
  if(apiData){
    for (let oeNumber in apiData) {
      apiData[oeNumber].sort((a, b) => (Number(a.price) - Number(b.price)))
    }
  }

  if(dbData){
    for (let oeNumber in dbData) {
      dbData[oeNumber].sort((a, b) => (Number(a.price) - Number(b.price)))
    }
  }

  function toObj(arr, i) {
	return arr[i];
  }

  function mergeValues(apiArray, dbArray) {
    let finalArray = [];
    if (apiArray && apiArray.length > 0) {
		for(let i=0; i<apiArray.length; i++){
			finalArray.push(toObj(apiArray, i))
		}}

    if (dbArray && dbArray.length > 0) {
		for(let i=0; i<dbArray.length; i++){
			finalArray.push(toObj(dbArray, i))
		}}
    return finalArray;
  }

  function checkingKeys(apiKeys, dbKeys){
		return apiKeys.length > 0 ? apiKeys : dbKeys;
	}

  let finalValue = {};
  let keys = checkingKeys(Object.keys(apiData), Object.keys(dbData));
  keys.forEach(element => {
    let finalArray = mergeValues(apiData[element], dbData[element]);
    finalValue[element] = finalArray;
  })
  console.log("finalValue", finalValue)
  return searchedText === siteConstants.vinLength ? finalValue : [apiData, dbData];
}

export function tryFetchingPartListFromOEAsync(oeNumbers, searchedText) { 
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken");

    dispatch(fetchingPartDetails(oeNumbers));
    dispatch(showPartsResultPage());

    if (!oeNumbers || !oeNumbers.length) {
      dispatch(onPartsListFromOEFetched({ status: "COMPLETED" }));
      return;
    }

    let dataToPost = {
        "oe_numbers": oeNumbers,
        "searched_brand": "",
      },
      configObject = {
        channel: siteConstants.PART_LIST_FROM_OE_CHANNEL + "_" + loginToken,
        stream: siteConstants.PART_LIST_FROM_OE_STREAM + "_" + utils.createUUID(),
        successCB: function (pusherEvent) {
          try {
            dispatch(watcherActions.clearWatcher(configObject.stream))
            console.log(
              " -- Message from ",
              configObject.channel,
              configObject.stream
            );
            console.log(" -- Pusher Event from FetchingPartListFromOEAsync ", {
              pusherEvent,
            });

            console.log("pusher List from OE Event", pusherEvent);
            const fetchFromS3 = pusherEvent.message && pusherEvent.message.meta && pusherEvent.message.meta["UPLOADED_TO_S3"]
            if(fetchFromS3){
              dispatch(tryFetchingPentanaPartsListFromS3API(pusherEvent.message));
            }else{ 
              let pusherResponse = pusherEvent.message;
              let sortedMergedData = mergeAndSortApiDbData(pusherEvent?.message?.payload, searchedText)
              pusherResponse.payload = sortedMergedData;
              dispatch(onPartsListFromOEFetched( pusherResponse ));
            }
          } catch (ex) {
            console.log(ex);
            dispatch(onPartsListFromOEFetched({ status: "COMPLETED" }));
          }

          DataPusher.unsubscribeFrom(configObject.channel);
        },
      };
    dataToPost.pub_sub_details = {
      "channel": configObject.channel,
      "event": configObject.stream,
    };

    if (dataToPost.oe_numbers && dataToPost.oe_numbers.length) {
      dataToPost.searched_brand = dataToPost.oe_numbers[0].brand;
    }

    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CUSTOMER-TOKEN": loginToken,
    };
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

    function bindCallback(){
      fetch(endpoints.PART_LIST_FROM_OE_ASYNC_API, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToPost),
      })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let response = jsonResponse,
          isSuccess =
            response && response.status && response.status.status == "SUCCESS"
              ? true
              : false,
          isUnauthorised =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "UNAUTHORIZED"
              ? true
              : false;
  
        if (isSuccess) {
          dispatch(onPartListSubscription(jsonResponse));
          dispatch(showPartsResultPage(jsonResponse));
        } else if (isUnauthorised) {
          console.error("Exception unauthorised");
        } else {
          window.location.href = "/";
        }
      })
      .catch((ex) => {
        console.log(ex);
        dispatch(onPartListSubscription({}));
      });
    }

    DataPusher.subscribeTo(configObject, bindCallback);

    dispatch(
      watcherActions.tryAddingToWatcher(
        configObject.stream,
        siteConstants.mediumTimeout,
        { configObject, loginToken, url: location.href }
      )
    );

  };
}

export function resetPartsData(data) {
  return { type: actionTypes.RESET_PARTS_DATA, data };
}


export const fastDeliveryRequestMade = (data) => {
	return { type: actionTypes.FAST_DELIVERY_REQUEST_MADE, data}
}

export const tryCreatingFastDeliveryRequest = ( dataToPost, partIndex ) => {
	return function (dispatch) {
		const loginToken = localStorage.getItem("loginAppToken");
		
		let headers = {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
			"X-CUSTOMER-TOKEN": loginToken,
		};
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

		fetch(endpoints.CREATE_FAST_DELIVERY_REQUEST, {
			method: "post",
			headers: headers,
			body: JSON.stringify(dataToPost)
		})
			.then(response => response.json())
			.then(jsonResponse => {
				const response = jsonResponse
				const isSuccess = response && response.status && response.status.status == "SUCCESS" ? true : false

				if ( isSuccess ) {
					dispatch(fastDeliveryRequestMade(partIndex))
					console.log("create fast delivery request have been made successfully !")
				} else {
					console.log("something went wrong during fast delivery request", response)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}
}

export const customerDetailsUpdated = (data) => {
	return { type: actionTypes.CUSTOMER_DETAILS_UPDATED, data}
}

export const tryUpdatingCustomerDetailsAndFastDeliveryRequest = ( dataToPost, partIndex ) => {
	return function (dispatch) {
		const loginToken = localStorage.getItem("loginAppToken");
		
		let headers = {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
			"X-CUSTOMER-TOKEN": loginToken,
		};
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

		fetch(endpoints.UPDATE_PROFILE, {
			method: "post",
			headers: headers,
			body: JSON.stringify(dataToPost.customerDetails )
		})
			.then(response => response.json())
			.then(jsonResponse => {
				const response = jsonResponse
				const isSuccess = response && response.status && response.status.status == "SUCCESS" ? true : false

				if ( isSuccess ) {
					dispatch( customerDetailsUpdated( dataToPost.customerDetails ) );
					dispatch( tryCreatingFastDeliveryRequest( dataToPost.fastDeliveryRequest, partIndex ) );
				} else {
					console.log("something went wrong during update customer request", response)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}
}