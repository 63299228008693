import * as actionTypes from "../constants/ActionTypes";
import * as endpoints from "../constants/Endpoints";
import * as siteConstants from "../constants/SiteConstants";
import * as utils from "../helpers/Utils";
import * as DataPusher from "../helpers/DataPusher";
import * as watcherActions from "./watcher";
import * as partsActions from '../actions/parts';

export function updateSearchObj(data) {
  return { type: actionTypes.UPDATE_PART_SEARCH_OBJECT, data };
}

export function updateSearchInput(data) {
  return { type: actionTypes.UPDATE_PART_SEARCH_INPUT, data };
}

export function loadStateFromLS(data) {
  return { type: actionTypes.LOAD_STATE_FROM_LS, data };
}

export function resetPartSearch(data) {
  return { type: actionTypes.RESET_PART_SEARCH, data };
}

export function showPartsSelector(data) {
  return { type: actionTypes.SHOW_PART_SELECTOR, data };
}

export function hidePartsSelector(data) {
  return { type: actionTypes.HIDE_PART_SELECTOR, data };
}

export function partSearchInProgress(data) {
  return { type: actionTypes.PART_SEARCH_IN_PROGRESS, data };
}

export function brandNameIdentified(data) {
  return { type: actionTypes.PART_BRAND_IDENTIFY_SEARCHED, data };
}

export function startFetchingGTMotiveEstimateId(data) {
  return { type: actionTypes.FETCH_GT_MOTIVE_EST_ID, data };
}

export function tryIdentifyingBrandName(searchString) {
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken"),
      queryString = "?search_string=" + searchString;

    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CUSTOMER-TOKEN": loginToken,
    };
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

    fetch(endpoints.PART_IDENTIFY_SEARCH_API + queryString, {
      method: "get",
      headers: headers,
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let isUnauthorised =
          jsonResponse &&
          jsonResponse.status &&
          jsonResponse.status.status == "UNAUTHORIZED"
            ? true
            : false;
        if (isUnauthorised) {
          console.error("Exception unauthorised");
        } else {
          dispatch(brandNameIdentified(jsonResponse.payload || {}));
        }
      })
      .catch((ex) => {
        console.log(ex);
        dispatch(brandNameIdentified({}));
      });
  };
}

export function licencePlateCallCompleted(data) {
  return { type: actionTypes.LICENCE_PLATE_CALL_COMPLETED, data };
}

export function licencePlateError(data) {
  return { type: actionTypes.LICENCE_PLATE_ERROR, data };
}

export const tryFetchingFinNumberFromLicencePlate = (plateNumber) => {
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken");
    const queryString = "?plate_number=" + plateNumber.toUpperCase();

    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CUSTOMER-TOKEN": loginToken
    };

    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }
    dispatch(partSearchInProgress());

    fetch(endpoints.FIN_NUMBER_FROM_LICENCE_PLATE + queryString, {
      method: "post",
      headers: headers,
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let isUnauthorised =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "UNAUTHORIZED"
              ? true
              : false,
          responseData =
            jsonResponse && jsonResponse.payload ? jsonResponse.payload : {};
          const isError = responseData?.is_error ? true : false

        if (isUnauthorised) {
          console.error("Exception unauthorised");
        } 
        else if(isError){
          dispatch(licencePlateError(responseData))
        } 
        else {
          // license call completed
          dispatch(licencePlateCallCompleted(responseData));
        }
      })
      .catch((ex) => {
        console.log(ex);
        dispatch(licencePlateError(ex));
      });
  };
};


export function updateDataPosted(data) {
  return { type: actionTypes.UPDATE_DATA_POSTED, data}
}

export function finValidationStarted(data) {
  return { type: actionTypes.PART_VALIDATING_FIN, data };
}

export function onFinValidated(data) {
  return { type: actionTypes.PART_FIN_VALIDATED, data };
}

const secretConstant =
window.partsPanelConfig.graphicSearchUrl && window.partsPanelConfig.apiKey
  ? {
      navigationBoardUrl: window.partsPanelConfig.graphicSearchUrl || "",
      apiKey: window.partsPanelConfig.apiKey || "",
    }
  : null;

const handleFetchParts = async (functionalObj, responseData) => {
  const fetchedData = await fetchDataFromTheZones(functionalObj.id, responseData);
  if (fetchedData?.length) {
    const combinedParts = fetchedData.reduce((acc, obj) => {
      if (obj.parts) {
        acc = acc.concat(obj.parts);
      }
      return acc;
    }, []);
    return { [functionalObj.id]: combinedParts }
  }

  return { [functionalObj.id]: [] };
}
  async function fetchDataFromTheZones(id, responseData) {
    let equipmentList = "";
    if (responseData.codes) {
      equipmentList = responseData.codes.join(",");
    }
    if (responseData.engine_codes) {
      equipmentList += ("," + responseData.engine_codes.join(","));
    }
    const url = `${secretConstant.navigationBoardUrl}/models/${responseData.vehicle_info.umc}/zones/${id}/vehiclerepresentation?&ApiKey=${secretConstant.apiKey}&jobType=All&language=en-GB&equipments=${equipmentList}&manufacturingValues=PMO F`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching parts (Status: ${response.status})`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  const flattenFunctionalGroups = (functionalGroups) => {
    if (!Array.isArray(functionalGroups)) return [];
    let resultArray = [];
    functionalGroups.forEach((group) => {
      if (group.functionalSubgroups && group.functionalSubgroups.length > 0) {
        resultArray.push(...group.functionalSubgroups);
      } else {
        resultArray.push(group);
      }
    });
    return resultArray;
  };

  const parsePartsData = (functionalGroupList) => {
    if (functionalGroupList && functionalGroupList.length > 0) {
      return functionalGroupList;
    }
    return [];
  };

export const updateVehicleInfoAndEngineCodes = (data) => {
  return async (dispatch) => {
    const responseData = data || {};
    let subCategoriesResponse = {};
    let categoriesWithFetchedParts = [];
    let equipmentList = "";
    if (responseData.codes) {
      equipmentList = responseData.codes.join(",");
    }
    if (responseData.engine_codes) {
      equipmentList += "," + responseData.engine_codes.join(",");
    }

    let apiCall = `${secretConstant.navigationBoardUrl}/models/${responseData.vehicle_info.umc}/navigationboards?language=en-GB&equipment=${equipmentList}&ApiKey=${secretConstant.apiKey}`;

    try {
      const response = await fetch(apiCall);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const fetchedData = await response.json();
      const flattenedData = flattenFunctionalGroups(fetchedData?.functionalGroups);
      // Find matched categories
      const matchedCategories = flattenedData.filter(group => siteConstants.PRIMARY_CATEGORY.includes(group.name))
      matchedCategories.forEach((item) => {
        item.name = siteConstants.CATEGORY_MAPPED[item.name] || item.name;
      })
      if (matchedCategories.length > 0) {
        const fetchResults = await Promise.all(
          matchedCategories.map(async (category) => {
            try {
              const response = await handleFetchParts(category, responseData);
              if (Object.keys(response).length > 0) {
                categoriesWithFetchedParts.push(category); // Add to fetched parts
              }
              return response;
            } catch (error) {
              console.error(`Error fetching parts for category ${category.name}:`, error);
              return { [category.id]: [] }; // Default empty structure
            }
          })
        )
        subCategoriesResponse = fetchResults.reduce((acc, cur) => ({ ...acc, ...cur }), {});
      } else {
        subCategoriesResponse = fetchedData; // Return as-is if no matches
      }
      const secondCategoryData = flattenedData.filter(group => siteConstants.SECONDARY_CATEGORY.includes(group.name));
      const remainingCategories = flattenedData.filter(
        (category) =>
          !categoriesWithFetchedParts.includes(category) &&
          !siteConstants.SECONDARY_CATEGORY.includes(category.name)
      )
      // Move categories with fetched parts to the top of the list
      const updatedData = [...categoriesWithFetchedParts, ...secondCategoryData, ...remainingCategories];

      const servicesList = parsePartsData(updatedData);

      // Dispatch the action with servicesList included
      dispatch({
        type: actionTypes.PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES,
        data: {
          ...responseData,
          servicesList,
          matchedCategories,
          subCategoriesResponse,
        },
      });
    } catch (error) {
      console.error("Error fetching services list: ", error);
    }
  };
};


export function tryFetchingPartListFromS3API(url) {
  return function (dispatch) {
    if (window.partsPanelConfig.corsUrl) {
      url = window.partsPanelConfig.corsUrl + url;
    }
    fetch(url, {
      method: "get",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let dataToBeUsed = {
          payload: jsonResponse.response.data,
          status: {
            status: "SUCCESS",
          },
        };
        dispatch(onFinValidated(dataToBeUsed));
      })
      .catch((ex) => {
        console.log(ex);
        dispatch(onFinValidated({}));
      });
  };
}

export function tryValidatingFinAndFetchingPartListAsync(finNumber) {
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken"),
       		queryString = "?fin=" + finNumber.toUpperCase();

    dispatch(finValidationStarted());

    let configObject = {
        channel: siteConstants.PART_EQUIPMENT_LIST_CHANNEL + "_" + loginToken,
        stream: siteConstants.PART_EQUIPMENT_LIST_STREAM + "_" + utils.createUUID(),
        successCB: function (pusherEvent) {
          try {
            console.log(
              " Message from ",
              configObject.channel,
              configObject.stream
            );
            console.log(
              "Pusher Event from FinAndFetchingPartListAsync ",
              pusherEvent.message.status,
              pusherEvent
            );
            let pusherResponse = pusherEvent.message.payload;
            switch (pusherEvent.message.status) {
              case siteConstants.PART_STREAM_LIST_UMC:
                if( pusherResponse ){
                  dispatch(watcherActions.clearWatcher(configObject.stream + "_1"))

                  dispatch(updateVehicleInfoAndEngineCodes(pusherResponse));
                }
                else{
                  dispatch(onFinValidated({}));
                  dispatch( partsActions.tryFetchingPartListFromOEAsync([]) );
                }
                break;

              case siteConstants.PART_STREAM_LIST_PART:
                if( pusherResponse && pusherResponse.url ){
                  dispatch(watcherActions.clearWatcher(configObject.stream + "_2"))
                  dispatch(tryFetchingPartListFromS3API(pusherResponse.url));
                }
								DataPusher.unsubscribeFrom(configObject.channel);
                break;
              default:
								DataPusher.unsubscribeFrom(configObject.channel);
                dispatch(onFinValidated({}));
            }
          } catch (ex) {
            console.log(ex);
            dispatch(onFinValidated({}));
          }
        },
      },
      dataToPost = {
        pub_sub_details: {
          "channel": configObject.channel,
          "event": configObject.stream,
        },
      };
    
    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CUSTOMER-TOKEN": loginToken
    };
    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

    function bindCallback(){
      fetch(endpoints.PART_GT_LIST_ASYNC_API + queryString, {
        method: "post",
        headers: headers,
        body: JSON.stringify(dataToPost),
      })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let isSuccess =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "SUCCESS"
              ? true
              : false,
          isUnauthorised =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "UNAUTHORIZED"
              ? true
              : false,
          isFailure =
            jsonResponse &&
            jsonResponse.status &&
            jsonResponse.status.status == "ERROR"
              ? true
              : false;
  
        if (isSuccess) {
          console.log(jsonResponse);
        } else if (isUnauthorised) {
          console.error("Exception unauthorised");
        } else {
          window.location.href = "/";
        }
      })
      .catch((ex) => {
        console.log(ex);
        dispatch(onFinValidated({}));
      });
    }
    DataPusher.subscribeTo(configObject, bindCallback);

    dispatch(
      watcherActions.tryAddingToWatcher(
        configObject.stream + "_1",
        siteConstants.mediumTimeout,
        { configObject, loginToken, url: location.href }
      )
    );

    dispatch(
      watcherActions.tryAddingToWatcher(
        configObject.stream + "_2",
        siteConstants.longTimeout,
        { configObject, loginToken, url: location.href }
      )
    );

  };
}

export function tryCreatingSessionLogin() {
  return function (dispatch) {
    const loginToken = localStorage.getItem("loginAppToken");

    if (loginToken) {
      console.log("-- Session Exists --");
      return;
    }

    let headers = {
      "Accept": "application/json, text/plain, */*",
      "Content-Type": "application/json"
    };

    if (window.partsPanelConfig.headerAuth) {
      headers.Authorization = window.partsPanelConfig.headerAuth || "";
    }

    fetch(endpoints.SESSIONS_LOGIN_API, {
      method: "post",
      headers: headers,
    })
      .then((response) => response.json())
      .then((jsonResponse) => {
        let responseData =
          jsonResponse && jsonResponse.payload ? jsonResponse.payload : {};

        localStorage.setItem("loginAppToken", responseData.token);
        utils.CookiesHelper.setItem(
          "loginAppToken",
          responseData.token,
          604800,
          endpoints.PARTS_ROOT_URL
        );
      })
      .catch((ex) => {
        console.log(ex);
      });
  };
}
