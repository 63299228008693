export const STORE_MAPPINGS = {
  STORE81: "Tauranga",
  STORE82: "Christchurch",
  STORE83: "Wellington",
  STORE1: "Tauranga",
  STORE2: "Christchurch",
  STORE5: "Wellington"
};

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_DISPLAY_FORMAT = "DD.MM.YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm";
export const DEFAULT_LOCALE = "en-NZ";

export const PART_EQUIPMENT_LIST_STREAM = "parts_list_stream";
export const PART_EQUIPMENT_LIST_CHANNEL = "private-mechanic-parts-list-channel";
export const PART_OE_STREAM = "parts_oe_stream";
export const PART_OE_CHANNEL = "private-mechanic-parts-OE-channel";
export const PART_LIST_FROM_OE_STREAM = "parts_list_from_oe_stream";
export const PART_LIST_FROM_OE_CHANNEL = "private-mechanic-parts-list-from-OE-channel";
export const PART_STREAM_STATUS_FETCHING = "fetching";
export const PART_STREAM_STATUS_COMPLETED = "completed";
export const PART_STREAM_LIST_UMC = "PEDNING";
export const PART_STREAM_LIST_PART = "COMPLETED";
export const PART_STATUS_NOT_FOUND = "PARTNOTFOUND";

//NavBar Sections
export const HOME_PAGE_PATH = "/";
export const NAVBAR_SECTION_SELECTION = "parts-selection";
export const IDENTIFY_VEHICLE_PATH = "identify-vehicle";
export const SELECTED_PARTS_PATH = "selected-parts";
export const DELIVERY_ADDRESS_PATH = "delivery-address";
export const PAYMENT_PATH = "payment";
export const ORDER_COMPLETE_PATH = "order-complete";

export const navStates = {
  identifyVehicle: {
    id: 1,
    urlPath: IDENTIFY_VEHICLE_PATH,
    displayText: "Identify vehicle",
  },
  selectedParts: {
    id: 2,
    urlPath: NAVBAR_SECTION_SELECTION,
    displayText: "Select Parts",
  },
  deliveryAddress: {
    id: 3,
    urlPath: DELIVERY_ADDRESS_PATH,
    displayText: "Delivery Address",
  },
  payment: {
    id: 4,
    urlPath: PAYMENT_PATH,
    displayText: "Payment",
  },
  orderComplete: {
    id: 5,
    urlPath: ORDER_COMPLETE_PATH,
    displayText: "Order Complete",
  },
};

// Delivery Address Sections
export const COUNTRY = "New Zealand";

// watcher constants
export const quickTimeout = 5000;
export const mediumTimeout = 10000;
export const longTimeout = 20000;
export const vinLength = 17;
export const cartExpireTimout = 3600000;
export const totalShippingETAConstant = 5;

export const PRIMARY_CATEGORY = [
  "AIR CONDITIONING",
  "FUEL TANK",
  "AIR FILTER",
  "AIRBAG, STEERING WHEEL AND STEERING WHEEL CONTROLS",
  "CHARGER AND STARTER SET",
  "CYLINDER HEAD, TIMING",
  "EXHAUST",
  "FRONT COOLING SYSTEM",
  "FRONT DOORS",
  "FRONT EXT BODYWORK",
  "FRONT INT BODYWORK",
  "FRONT RUNNING GEAR",
  "FULL GEARBOX, CLUTCH",
  "GEARBOX AND STEERING CONTROLS",
  "GENERAL MOTOR, PISTONS, CONNECTING RODS AND CRANKSHAFT",
  "IGNITION SYSTEM",
  "INJECTION",
  "LUBRICATION",
  "MOUNTS, ENGINE SET (ENGINE +GEARBOX)",
  "REAR RUNNING GEAR",
  "STEERING",
  "TURBO COMPRESSOR",
  "WATER PUMP AND COOLING PIPES"
];

export const SECONDARY_CATEGORY = [
  "ALARM",
  "AUDIO SYSTEMS",
  "CEN INT BODYWORK",
  "DASHBOARD",
  "DIFFERENTIAL AND FORKS",
  "FRONT SEATS",
  "MOULDINGS AND TRIMS",
  "REAR EXT BODYWORK",
  "REAR INT BODYWORK",
  "REAR SEATS ROW 2",
  "REAR/SIDE DOORS",
  "SUNROOF",
];

export const CATEGORY_MAPPED = {
  "FRONT DOORS": "FRONT DOORS (FRONT DOORS, MIRRORS, GLASS, SWITCHES, LAMPS)",
  "FRONT RUNNING GEAR": "FRONT RUNNING GEAR (FRONT BRAKE, DRIVE, SUSPENSION COMPONENTS)",
  "LUBRICATION": "LUBRICATION AND OIL FILTER ASSEMBLY",
  "REAR RUNNING GEAR": "REAR RUNNING GEAR (REAR BRAKE, DRIVE, SUSPENSION COMPONENTS)",
  "FULL GEARBOX, CLUTCH": "FULL GEARBOX, AND CLUTCH COMPONENTS",
}